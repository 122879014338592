
import '../App.css';
import background from '../img/2.jpg'
import rocket from '../img/rocket.gif'
import React from 'react';
import { useEffect } from 'react';

function Loader(page) {

    return (
        <div class="loader loader--reload">
            <div class="section__bg">
                <div class="bg-steps"> </div>
            </div>
            <div class="loader__body">
                <div class="loader__icon center"> <img alt="loader" src={rocket} width={190} height={190}  /></div>
                <div class="loader__title">{page.page === 7 ? 'Tu futuro está cerca de despegar' : 'Cargando...' }</div>
                <p class="loader__description">{page.page === 7 ? 'Estamos calculando tu resultado': ''}</p>
            </div>
        </div>
    );
}

export default Loader;
