import '../App.css';
import { useEffect, useState } from 'react';

import Nombre from '../components/steps-2/nombre';
import Telefono from '../components/steps-2/telefono';
import Apellido from '../components/steps-2/apellido';
import Region from '../components/steps-2/region';
import Comuna from '../components/steps-2/comuna';
import Curso from '../components/steps-2/curso';
import Colegio from '../components/steps-2/colegio';
import React from 'react';

function Steps2({
    apellidoAlert,
    colegioAlert,
    colegios,
    comunaAlert,
    comunas,
    cursoAlert,
    cursos,
    estudiante,
    extranjeroid,
    loaderColegio,
    loaderComuna,
    nombreAlert,
    regionAlert,
    regiones,
    setApellidoAlert,
    setColegioAlert,
    setComunaAlert,
    setCursoAlert,
    setEstudiante,
    setNombreAlert,
    setRegionAlert,
    setTelefonoAlert,
    telefonoAlert,
}) {
    const [apellido, setApellido] = useState(estudiante.estudiante.apellido);
    const [colegio, setColegio] = useState(estudiante.estudiante.es_extranjero ? '' : estudiante.estudiante.colegio?.id_colegio);
    const [comuna, setComuna] = useState(estudiante.estudiante.es_extranjero ? '' : estudiante.estudiante.colegio?.comuna.id_comuna);
    const [curso, setCurso] = useState(estudiante.estudiante.id_curso);
    const [loader, setLoader] = useState(false);
    const [nombre, setNombre] = useState(estudiante.estudiante.nombre);
    const [region, setRegion] = useState(estudiante.estudiante.es_extranjero ? extranjeroid : estudiante.estudiante.colegio?.comuna?.region?.id_region);
    const [telefono, setTelefono] = useState(estudiante.estudiante.celular);

    useEffect(() => {
        setLoader(true);
        let est = estudiante;
        let colegioOBJ = {};
        let comunaOBJ = {};
        if (extranjeroid === region) {
            est.estudiante.es_extranjero = true;
        } else {
            est.estudiante.es_extranjero = false;
        }
        est.estudiante.nombre = nombre || '';
        est.estudiante.apellido = apellido || '';
        est.estudiante.celular = telefono || '';
        est.estudiante.id_colegio = parseInt(colegio) || null;
        if (!estudiante.estudiante.es_extranjero) {
            comunaOBJ.id_region = parseInt(region) || '';
            colegioOBJ.id_comuna = parseInt(comuna) || '';
            colegioOBJ.id_colegio = parseInt(colegio) || '';
            colegioOBJ.comuna = comunaOBJ;
        } else {
            setColegio('');
            setComuna('');
        }
        est.estudiante.colegio = colegioOBJ;
        est.estudiante.id_curso = parseInt(curso);
        setEstudiante(est);
        setLoader(false);
    }, [apellido, colegio, comuna, curso, estudiante, extranjeroid, nombre, region, setEstudiante, telefono]);
    return (
        <>
            {!loader ? (
                <div>
                    <section className="section--steps">
                        <div class="section__step">
                            <div className="section__header">
                                <h2 class="section__title">
                                    <strong>¡Hola!</strong> Danos tus datos
                                </h2>
                                <p class="section__description">para poder armar tu perfil</p>
                            </div>
                            <div class="section__body">
                                <div class="section__bg">
                                    <div class="bg-steps"> </div>
                                </div>
                                <div class="section__wrap section__wrap--md">
                                    <div class="cols">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="section__form__box section__form__box--minheight">
                                                <div class="section__form__title">Información del estudiante</div>
                                                <Nombre
                                                    nombre={nombre}
                                                    setNombre={setNombre}
                                                    nombreAlert={nombreAlert}
                                                    setNombreAlert={setNombreAlert}
                                                />

                                                <Apellido
                                                    apellido={apellido}
                                                    setApellido={setApellido}
                                                    apellidoAlert={apellidoAlert}
                                                    setApellidoAlert={setApellidoAlert}
                                                />

                                                <Telefono
                                                    telefono={telefono}
                                                    setTelefono={setTelefono}
                                                    telefonoAlert={telefonoAlert}
                                                    setTelefonoAlert={setTelefonoAlert}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="section__form__box section__form__box--minheight">
                                                <div class="section__form__title">Información del colegio</div>
                                                <Region
                                                    region={region}
                                                    setRegion={setRegion}
                                                    regionAlert={regionAlert}
                                                    setRegionAlert={setRegionAlert}
                                                    regiones={regiones}
                                                    setColegio={setColegio}
                                                    setComuna={setComuna}
                                                />
                                                {region !== '' && region !== extranjeroid && region !== undefined ? (
                                                    <>
                                                        <Comuna
                                                            region={region}
                                                            comuna={comuna}
                                                            setComuna={setComuna}
                                                            comunaAlert={comunaAlert}
                                                            setComunaAlert={setComunaAlert}
                                                            comunas={comunas}
                                                            loaderComuna={loaderComuna}
                                                        />
                                                    </>
                                                ) : null}
                                                {comuna !== '' && region !== extranjeroid && region !== undefined ? (
                                                    <>
                                                        <Colegio
                                                            colegio={colegio}
                                                            comuna={comuna}
                                                            setColegio={setColegio}
                                                            colegioAlert={colegioAlert}
                                                            setColegioAlert={setColegioAlert}
                                                            colegios={colegios}
                                                            loaderColegio={loaderColegio}
                                                        />
                                                    </>
                                                ) : null}
                                                <Curso
                                                    cursos={cursos}
                                                    curso={curso}
                                                    setCurso={setCurso}
                                                    cursoAlert={cursoAlert}
                                                    setCursoAlert={setCursoAlert}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : null}
        </>
    );
}

export default Steps2;
