import APIS from "../service/apis";

// const url = 'https://d24nhxl4yamrul.cloudfront.net/backend_data/';

export const getTDocuments = async (flag) => {
  if (flag) {
    return {
      data: [
        { id_tipo_documento: 2, tipo_documento: "Pasaporte" },
        { id_tipo_documento: 1, tipo_documento: "RUT" },
      ],
    };
  }
  return APIS.getTiposDocumentos();
};
export const getEspejos = async (flag) => {
  if (flag) {
    return {
      data:{
      data: [
        {espejo: "nombre", path: "/nombre", captcha: false, periodoDemre: false},
        {espejo: "nombre2", path: "/nombre2", captcha: true, periodoDemre: true}
      ],
      }
    };
  }
  return APIS.getEspejos();
};
export const openingData = (jwt) => {
  return APIS.getOpeningData(jwt);
};

export const crearEstudiante = async (
  flag,
  DOCUMENTO,
  tipo_documento,
  email,
  feria
) => {
  const postObject = {
    email: email,
    id_tipo_documento: tipo_documento,
    numero_documento: DOCUMENTO,
  };

  if (feria.length > 1) {
    return APIS.simulacionStartFeria(postObject, feria);
  }

  return APIS.simulacionStart(postObject);
};

export const cargaTDocument = async (MOCK, setDocument, setShowInitButton) => {
  await getTDocuments(MOCK).then((data) => {
    setDocument(data.data.data);
    setShowInitButton(true);
  });
};

export const cargaEspejos = async (MOCK) => getEspejos(MOCK)