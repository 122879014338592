import { createContext, useReducer, useEffect } from 'react';

const initialState = {};

const reducer = (jwt, newJwt) => {
  if (!newJwt) {
    localStorage.removeItem('jwt');
    return initialState;
  }
  return { ...jwt, ...newJwt };
};

const localState = JSON.parse(localStorage.getItem('jwt'));

const JwtContext = createContext({});

const JwtProvider = ({ children }) => {
  const [jwt, setJwt] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem('jwt', JSON.stringify(jwt));
  }, [jwt]);

  return <JwtContext.Provider value={{ jwt, setJwt }}>{children}</JwtContext.Provider>;
};

const logout = () => {
  localStorage.clear();
  window.location.reload(false);
};

export { JwtContext, JwtProvider, logout };
