import React from 'react'

const NumDoc = ({ numDoc,setNumDoc,numDocAlert,setNumDocAlert,tipoDoc}) => {
    function formatRut(rut) {
        // Ensure input is a string
        rut = String(rut);

        // Remove any non-digit character
        rut = rut.replace(/[^\dKk]+/g, '');

        // Split the RUT into the number and the verification digit
        let number = rut.slice(0, -1);
        const verificationDigit = rut.slice(-1);

        // Format the RUT with dots and a slash
        let formattedRut = "";
        let i = 0;
        while (number.length > 3) {
            formattedRut = "." + number.slice(-3) + formattedRut;
            number = number.slice(0, -3);
            i++;
        }
        formattedRut = number + formattedRut + "-" + verificationDigit;

        // Return the formatted RUT
        return formattedRut;
    }
    const handleChangeNumDoc = (value) => {
        if(tipoDoc == 1){
            // Format the input value
            const formattedValue = formatRut(value);
            // Update the state with the formatted value
            setNumDoc(formattedValue);
        }else{
        setNumDoc(value);
        }
    };
return (
    <>
    <div className="form__element"> 
        <div className="form__title">
            N° RUT o Pasaporte
            { numDocAlert &&   
              <div class="flex">
                <div class="validationErrorText">
                RUT inválido
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-exclamation-diamond-fill error_icon" viewBox="0 0 16 16">
                    <path fill="#FF0000" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
              </div>
            } 
        </div>
        <div className="form__input"> 
            <input 
                type="text"
                value={numDoc}
                name="docnum" 
                placeholder="Ingrese su N°" 
                onChange={(e)=>{
                    handleChangeNumDoc(e.target.value) 
                    setNumDocAlert(false)
                    }}/>
        </div>
    </div>
    </>
)
}

export default NumDoc