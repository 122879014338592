import '../App.css';
import React from 'react';

function Steps4({ estudiante, setLoading }) {
    setLoading(false);
    const tabla = {
        materias: [
            { id_materia: 1, nombre_materia: 'Matemática 1', notas: [], notaMax: 0, order: 1 },
            { id_materia: 2, nombre_materia: 'Matematica 2', notas: [], notaMax: 0, order: 2 },
            { id_materia: 3, nombre_materia: 'Historia y CCSS', notas: [], notaMax: 0, order: 3 },
            { id_materia: 8, nombre_materia: 'Ciencias', notas: [], notaMax: 0, order: 4 },
            { id_materia: 10, nombre_materia: 'Comprensión Lectora', notas: [], notaMax: 0, order: 5 },
        ],
    };
    const notasMax = Array(5).fill(0);
    const CargarNotasMax = () => {
        estudiante.estudiante.pruebas.forEach((prueba) => {
            prueba.materias.forEach((materia, index) => {
                if (notasMax[index] < materia.puntaje) {
                    notasMax[index] = materia.puntaje;
                }
            });
        });
    };

    // Función para obtener los nombres de las materias
    const obtenerNombresMaterias = () => {
        const materias = new Set();
        estudiante.estudiante.pruebas.forEach((prueba) => {
            prueba.materias.forEach((materia) => {
                materias.add(materia.nombre_materia);
            });
        })
        return Array.from(materias);
    };

    //  Función para obtener las puntuaciones de las pruebas para cada materia
    const obtenerPuntuacionesPorMateria = (tipoPruebaIndex) => {
        return estudiante.estudiante.pruebas.map((tipoPrueba) => tipoPrueba.materias[tipoPruebaIndex].puntaje);
    };
    CargarNotasMax();

    return (
        <div>
            <section className="section--steps">
                <div class="section__step">
                    <div className="section__header">
                        <h2 class="section__title">
                            <strong>Resumen de tus puntajes</strong>
                        </h2>
                        <p class="section__description">Usamos tus máximos puntajes para la simulación</p>
                    </div>
                    <div class="section__body">
                        <div class="section__bg">
                            <div class="bg-steps"> </div>
                        </div>
                        <div class="section__wrap section__wrap--md">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Materia</th>
                                        {estudiante.estudiante.pruebas.map((element) => {
                                            return <th>{element.nombre_tipo_prueba}</th>;
                                        })}
                                        <th>
                                            <span>PUNTAJE FINAL</span>
                                            <div class="table__info">
                                                <div class="table__circle">
                                                    <span>i</span>
                                                </div>
                                                <div class="table__tooltip">
                                                    <h5>Puntaje final: </h5>
                                                    <p>El puntaje final de postulación se calcula considerando el máximo puntaje obtenido en las pruebas para cada materia. </p>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {obtenerNombresMaterias().map((nombreMateria, indez) => {
                                        return (
                                            <tr key={indez}>
                                                <td>{nombreMateria}</td>
                                                {estudiante.estudiante.pruebas.map((pruebas, index) => {
                                                    return (<td>{obtenerPuntuacionesPorMateria(indez)[index]}</td>)
                                                })}
                                                <td>{notasMax[indez]}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Steps4;
