import '../App.css';
import { useState } from 'react';
import Header from '../components/header';
import SectionContent from '../components/home/sectioncontent';
import Footer from '../components/footer';
import React from 'react';

function Home({ start, opening, msg, loading, showInitButton }) {
  return (
    <div>
      <Header isHomeScreen />

      <div id="sections">
        <section className="section--main">
          {!opening ? (
            <>
              <div className="section__bg">
                {' '}
                <div className="bg"></div>
                {
                  msg ?
                    <p className="opening_msg">
                      {msg}
                    </p> :
                    <div className="opening_msg">
                      <div> ¡Gracias por preferirnos! Nuestro simulador ya ha finalizado su proceso,</div>
                      <div> te esperamos para la Admisión 2024, ¡nos vemos! TKM :D</div>
                    </div>
                }
              </div>
            </>
          ) : (
            <>
              {/* <div className="section__bg">
        {' '}
        <div className="bg-steps"></div>
       </div> */}
              <div className="section__body_home">
                <SectionContent
                  start={start}
                  loading={loading}
                  showInitButton={showInitButton}
                />
              </div>
              <div className="section__description_bottom">
              </div>
            </>
          )}
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
