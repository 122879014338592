import axiosApiInstance from "./axios";
import cloudfrontApiInstance from "./axiosCloudfront"

const options = { crossDomain: true };
const APIS = {
  simulacionEnd: async (obj, jwt) => {
    return axiosApiInstance.post(`/pregrado/simulacion/end`, obj, { ...options, headers: { authorization: jwt } });
  },
  simulacionStart: async (obj) => {
    return axiosApiInstance.post(`/pregrado/simulacion/start`, obj, options);
  },
  simulacionStartFeria: async (obj, feria) => {
    return axiosApiInstance.post(
      `/pregrado/simulacion/start${feria}`,
      obj,
      options
    );
  },
  getOpeningData: async (jwt) => {
    return cloudfrontApiInstance.get(`/backend_data/aperturas.json`);
  },
  getColegios: async () => {
    return cloudfrontApiInstance.get(`/backend_data/colegios.json`);
  },
  getCursos: async () => {
    return cloudfrontApiInstance.get(`/backend_data/cursos.json`);
  },
  getCertificaciones: async (idEstudiante, jwt) => {
    return axiosApiInstance.get(`/pregrado/carrito/${idEstudiante}`, { 'headers': { authorization: jwt } });
  },
  getCurso: async (curso, id_estudiante, jwt) => {
    return axiosApiInstance.get(`/pregrado/pruebas/${curso}/${id_estudiante}`, { 'headers': { authorization: jwt } });
  },
  getPuntajes: async (id_estudiante, id_tipo_prueba, jwt) => {
    return axiosApiInstance.get(`/puntajes/${id_estudiante}/${id_tipo_prueba}`, { 'headers': { authorization: jwt } });
  },
  postPuntajesDemre: async (obj, jwt) => {
    return axiosApiInstance.post(`/pregrado/demre`, obj, { ...options, headers: { authorization: jwt } });
  },
  postActualizarData: async (obj, jwt) => {
    return axiosApiInstance.post(`/pregrado/estudiante`, obj, { ...options, headers: { authorization: jwt } });
  },
  putActualizarData: async (obj, jwt) => {
    return axiosApiInstance.put(`/pregrado/estudiante`, obj, { ...options, headers: { authorization: jwt } });
  },
  getTiposDocumentos: async () => {
    return cloudfrontApiInstance.get(`/backend_data/tipos_documento.json`);
  },
  getEspejos: async () => {
    return cloudfrontApiInstance.get(`/backend_data/ferias.json`);
  },
  getDecilesPregrado: async () => {
    return cloudfrontApiInstance.get(`/backend_data/deciles_pregrado.json`);
  },
  getCarreras: async () => {
    return cloudfrontApiInstance.get(`/backend_data/carreras_pregrado.json`);
  },
  sendCertificado: async (obj, certificado, jwt) => {
    return axiosApiInstance.post(
      `/pregrado/certificado/enviar/` + certificado,
      obj,
      { ...options, headers: { authorization: jwt } }
    );
  },
  sendByMailCertificacion: async (idCertificacion, jwt) => {
    return axiosApiInstance.post(
      `pregrado/enviar_informacion/${idCertificacion}`, {},
      { 'headers': { authorization: jwt } }
    );
  },
  EmitirCertificacion: async (idCertificacion, jwt) => {
    return axiosApiInstance.post(
      `/pregrado/certificado/enviar/${idCertificacion}`, {},
      { 'headers': { authorization: jwt } }
    );
  },
};

export default APIS;
