import React from "react";
import "./buttons-footer.css";

const ButtonsFotter = ({ espejo, setAuthorizedDemre, disableByDemre, progressBar, page, setPage, pagesLength, validation, SetPageNext, loader, setloader, tagManagerStepper }) => {
  const isAllowedToPass = async () => {
    if (await validation(page)) {
      tagManagerStepper(page);
      SetPageNext((currentPage) => currentPage + 1);
    }
  };
  const isDisabled = page === 3 ? disableByDemre : false
  return (
    <>
      <div className="section__footer  footer-buttons">
        <div className="section__buttons">
          <button
            type="button"
            className="btn btn--gray"
            disabled={loader}
            onClick={() => {
              setAuthorizedDemre(false)
              SetPageNext((currentPage) => currentPage - 1);
            }}
          >
            Volver
          </button>
          <button
            type="button"
            className="btn"
            disabled={loader || isDisabled}
            onClick={() => {
              setAuthorizedDemre(false)
              isAllowedToPass();
            }}
          >
            {loader ? (
              <>
                <div className="spinner"></div>
              </>
            ) : (
              <>
                <div>Siguiente</div>
              </>
            )}
          </button>
        </div>
        <div className="section__progress">
          <div className="section__number">
            {page}/{pagesLength}
          </div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: progressBar }}
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ButtonsFotter;
