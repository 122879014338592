import React from 'react';
import logoCert1Img from '../img/logo_cert_1b.png';

import logoCertBarra from '../img/logo_cert_barra.png';
import ContactUsFooter from './footer-elements/contactus';
import SocialNetworkFooter from './footer-elements/socialnetwork';
const Footer = ({ title }) => {
  return (
    <>
      <footer>
        <div className="footer__wrap">
          <div className="cols">
            <div className="footer__flex footer__flex--mobile">
              <div className="col-xs-6">
                <div className="footer__icon">
                  <img src={logoCert1Img} alt="logo-certificacion-universidad" width={1000} height={200} />
                </div>
              </div>
              <div className="col-xs-6">
                <ContactUsFooter />
              </div>
            </div>
            <div className="footer__flex">
              <div className="col-lg-6">
                <div className="footer__icons">
                  <img src={logoCertBarra} alt="logo-certificacion-universidad-barra" width={1000} height={200} />
                </div>
              </div>
              <div className="col-lg-3">
                <SocialNetworkFooter />
              </div>
              <div className="col-lg-3">
                <ContactUsFooter />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
