import '../App.css';
import Captcha from '../components/steps-1/captcha';
import Email from '../components/steps-1/email';
import NumDoc from '../components/steps-1/num-doc';
import React from 'react';
import TipoDoc from '../components/steps-1/tipo-doc';

function Steps1({
  tieneCaptcha,
 captcha,
 captchaAlert,
 email,
 emailAlert,
 numDoc,
 numDocAlert,
 setCaptcha,
 setCaptchaAlert,
 setEmail,
 setEmailAlert,
 setNumDoc,
 setNumDocAlert,
 setTipoDoc,
 setTipoDocAlert,
 tdocument,
 tipoDoc,
 tipoDocAlert,
 msg,
 opening
}) {
 return (
  <div>
   <section className="section--steps">
    <div className="section__step">
      {!opening ?
        <div className="section__header margin">
          <h2 className="section__title">
            <strong>{msg}</strong>
          </h2>
        </div> :
        <>
          <div className="section__header">
            <h2 className="section__title">
            <strong>¡Hola!</strong> Danos tus datos
            </h2>
            <p className="section__description">para poder armar tu perfil</p>
          </div>
          <div className="section__body">
            <div className="section__bg">
            <div className="bg-steps"> </div>
            </div>
              <div className="section__wrap">
              <div className="section__form__box">
                <div className="section__form__title">Información del estudiante</div>
                {/* <TipoDoc .....*/}

                <TipoDoc
                tdocument={tdocument}
                tipoDoc={tipoDoc}
                setTipoDoc={setTipoDoc}
                tipoDocAlert={tipoDocAlert}
                setTipoDocAlert={setTipoDocAlert}
                numDoc={numDoc}
                setNumDoc={setNumDoc}
                />

                {/* <NumDoc */}
                <NumDoc
                numDoc={numDoc}
                setNumDoc={setNumDoc}
                numDocAlert={numDocAlert}
                setNumDocAlert={setNumDocAlert}
                tipoDoc={tipoDoc}
                />
                {/* <Email */}
                <Email
                email={email}
                setEmail={setEmail}
                emailAlert={emailAlert}
                setEmailAlert={setEmailAlert}
                />
              {tieneCaptcha &&
                <Captcha
                captcha={captcha}
                setCaptcha={setCaptcha}
                captchaAlert={captchaAlert}
                setCaptchaAlert={setCaptchaAlert}
                />
              }
              </div>
            </div>
        </div>
      </>
      }
    </div>
   </section>
  </div>
 );
}

export default Steps1;
