import TagManager from 'react-gtm-module-nonce-aware'

const GTMKey = process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY

export const inicializarTagManager = () => {
  TagManager.initialize({
    gtmId: GTMKey,
    events: {
      checkout: 'checkout',
      intermedio: 'intermedio',
      SIMbyb: 'SIMbyb',
      purchase: 'purchase',
    },
    nonce: "noncegenerated-at-runtime"
  });
}